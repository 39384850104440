@import "@tiller-ds/theme/styles/tiller.css";
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "biotif";
    src: url('font/biotif-1/Biotif-Regular.ttf');
}

body {
    overflow-x: hidden;
}

.day-event {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    white-space: nowrap !important;
    border-top-width: 2px !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 4px !important;
    overflow-x: clip !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    cursor: pointer;
}

.week-event {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    white-space: nowrap !important;
    border-width: 0 !important;
    overflow-x: clip !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    cursor: pointer;
}

.month-event {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    white-space: nowrap !important;
    border-width: 0 !important;
    overflow-x: clip !important;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
    cursor: pointer;
    height: 25px;
    width: 25px;
    border-radius: 50% !important;
    display: inline-block;
}

@media screen and (min-width: 768px) {
    .week-event {
        border-top-width: 2px !important;
        border-left-width: 4px !important;
    }

    .month-event {
        height: auto;
        width: auto;
        border-radius: 25px !important;
    }
}

.meeting {
    background-color: #166534 !important;
    color: #166534 !important;
    border-color: #166534 !important;
}

.training {
    background-color: #1E40AF !important;
    color: #1E40AF !important;
    border-color: #1E40AF !important;
}

.competition {
    background-color: #6B21A8 !important;
    color: #6B21A8 !important;
    border-color: #6B21A8 !important;
}

.general {
    background-color: #9D174D !important;
    color: #9D174D !important;
    border-color: #9D174D !important;
}

@media screen and (min-width: 768px){
    .meeting{background-color: #DCFCE7 !important;}
    .training{background-color: #DBEAFE !important;}
    .competition{background-color: #F3E8FF !important;}
    .general{background-color: #FCE7F3 !important;}
}


.time-grid-meeting {
    background-color: #DCFCE7 !important;
    color: #166534 !important;
    border-color: #166534 !important;
}

.time-grid-training {
    background-color: #DBEAFE !important;
    color: #1E40AF !important;
    border-color: #1E40AF !important;
}

.time-grid-competition {
    background-color: #F3E8FF !important;
    color: #6B21A8 !important;
    border-color: #6B21A8 !important;
}

.time-grid-general {
    background-color: #FCE7F3 !important;
    color: #9D174D !important;
    border-color: #9D174D !important;
}



.black-dot {
    border-radius: 50%;
    text-align: center;
    height: 2em;
    width: 2em;
    padding-top: 5px;
}

.break-word {
    word-break: break-word;
}

.show-first-four > div:nth-child(n+5){
 display: none;
}

.fc .fc-timegrid-slots {
    z-index: 0 !important;
}

tr > th:first-child, tr > th:last-child, tr > td:first-child, tr > td:last-child {
    position: relative;
}

.input > p{
    font-weight: normal !important;
}

.labelToggleOneLine > div{
    display: flex !important;
    justify-content: space-between !important;
}

